import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo' 

export default function Baum(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >   
        <MySeo title="Das Schicksal der jüdischen Familie Keller aus Bacharach" />
        <Box  mt="60px"><Link to="/bacharach"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid mt="60px" gap={4} columns={[1,1,2]}>
        <Box>
            <Heading fontSize="lg" py="8">Ludwig Baum</Heading>
            
            <Text variant="solid">
            Ludwig Baum wurde am 30. März 1865 in Thalfang geboren. Er war der Sohn von Salomon Baum und Philippina Baum, geb. Mayer. Mit 27 Jahren heiratete er Regine Sommer in Bacharach. Kurz nach der Heirat kaufte er ein Haus in der Bauerstraße 1a und betrieb bis 1934 dort eine Metzgerei. Nach 25 Jahren Ehe verstarb seine Frau. Bald darauf heiratete er die 15 Jahre jüngere Regina Levy.
            </Text>
           
            <Text variant="solid">
            Metzger Baum hatte im Ersten Weltkrieg im Deutschen Heer gekämpft. Er war Mitglied des Kriegervereins Bacharach und hatte bei der Beisetzung des Weinhändlers Heinrich Müller im März 1933 die ehrenvolle Aufgabe, die Orden des Verstorbenen auf einem Kissen ausgebreitet dem Sarg voran zu tragen. Doch bald schon wendete sich das Blatt und die Aggressionen gegen die jüdische Bevölkerung wurden offensichtlich. So zerrten im Jahr darauf zwei SS-Offiziere den fast 70-jährigen Ludwig Baum aus dem Haus, drückten ihm eine Schelle in die Hand und hängten ihm ein Schild um den Hals, auf dem sinngemäß stand: „Ich bin der größte Schweinejude, ich habe heute noch geschächtet.“ Sie trieben ihn durch die gesamte Stadt. Wenn er nicht schellte, versetzten ihm die Männer Fußtritte. 
            </Text>
            <Text variant="solid">
            Verstört und gedemütigt von diesen Ereignissen verließen er und seine Frau Bacharach. Sie zogen zunächst nach Hilbringen an der Saar, dann in die jüdische Gemeinde Worms. Doch auch dort waren sie nicht sicher. Am 27. September 1942 wurden Ludwig Baum und seine Frau von Darmstadt aus in das Ghetto Theresienstadt in der besetzten Tschechoslowakei deportiert, wo er bereits am 6. November starb. Seine Frau verstarb eineinhalb Jahre später. 
            </Text>
            
           
            </Box>
            <Box  my="9">
                <Box pt={["1","1","16"]}>
                    <StaticImage src="../images/bacharach/01.jpg" alt="Gustav und Henriette Gerson &amp; Else Trum"/>
                    <Text variant="Bild">Ludwig Baum </Text>
                </Box>
               
                
            </Box>
            </SimpleGrid>
            </motion.div>
            
    )
}